import React from "react";
import { useState } from "react";
import {
  Container,
  Button,
  OffcanvasBody,
  OffcanvasHeader,
  Offcanvas,
  Card,
} from "reactstrap";
import "./Recruitment.scss";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { IoReloadOutline } from "react-icons/io5";
import { Link } from "react-router-dom";

const Requisitions = (props) => {
  const [isRight, setIsRight] = useState(false);
  const [activeTab, setActiveTab] = useState("1");
  const [activeTab1, setActiveTab1] = useState("5");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) setActiveTab1(tab);
  };

  const toggleRightCanvas = () => {
    setIsRight(!isRight);
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="d-flex">
          
          </div>

          {/* <Card>
          <div class="top">
  <a class="active" href="#home">Home</a><div class="vl"></div>
  <div className=" News d-flex">
  <a href="#news">News</a>
  <a href="#contact">Contact</a>
  </div>
</div>
</Card> */}
          <div className="app">
            <div className="header">
              <div>
                <strong className="Requisi-side" style={{color:"black", fontSize:"20px",fontWeight:"10px"}}>Requisitions</strong>
              </div>
              
              <div className="space">
                <Link className="text-decoration" to="/AllRequisitions" >
                  <span className="border" style={{margin:"20px", left:"18px;"}}></span> ALL REQUISITIONS
                </Link>
              </div>
              <div className="">
                <Link className="text-decoration" href="#" >
                  REQUISITION TEMPLATES
                </Link>
              </div>
              <div className="breadcrumb"> <Link className="" style={{fontSize:"14px"}}>Home</Link> / Requisitions</div>
            </div>

            <div className="sidebar">
            
              <div className="views">
              <h2>views</h2>
                <a href="#" className="active">
                  All Requisitions
                </a>
                <a href="#">My Submitted Requisitions</a>
                <a href="#">Approved Requisitions</a>
                <a href="#">Rejected Requisitions</a>
                <a href="#">Draft Requisitions</a>
                <a href="#" className="create-view">
                  Create View +
                </a>
              </div>
            </div>

          </div>

          <div className="d-flex  gap-3">
            <Button
              color="primary"
              onClick={toggleRightCanvas}
              style={{ margin: "10px" }}
            >
              All Templates
            </Button>
          </div>
          <Offcanvas
            direction="end"
            isOpen={isRight}
            toggle={toggleRightCanvas}
            id="offcanvasRight"
            className="custom-offcanvas"
          >
            <OffcanvasHeader toggle={toggleRightCanvas}>
              Create Templates
            </OffcanvasHeader>
            <OffcanvasBody>
              <div className="NSS_templates">
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    Templates Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    primary Skill <IoReloadOutline />
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
              </div>
              <div className="NSS_templates">
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    Templates Name <span style={{ color: "red" }}>*</span>
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    Title <span style={{ color: "red" }}>*</span>
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
                <div className="mb-3 col-lg-3">
                  <label className="form-label" for="name">
                    primary Skill <IoReloadOutline />
                  </label>
                  <input type="text" id="name" className="form-control" />
                </div>
              </div>
            </OffcanvasBody>
          </Offcanvas>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Requisitions;
