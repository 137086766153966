import React from "react";

import Dashboard from "../pages/Dashboard/index";
import Requisitions from "../pages/Recruitment/Requisitions"
import Login from "pages/AuthenticationInner/Login";
import AllRequisitions from "pages/Recruitment/AllRequisitions";
import Users from "pages/Tasks/Tasks";

const userRoutes = [
  { path: "/", component: <Dashboard /> },
  {
    path: "tasks",
    component: <Users />,
  },
  // wildcard page
  { path: "*", component: <Dashboard /> },

  //Requisitions page
  { path: "/Requisitions", component: <Requisitions /> },

  { path: "/AllRequisitions", component: <AllRequisitions /> },
];

const authRoutes = [{ path: "/login", component: <Login /> }];

export { userRoutes, authRoutes };
