import { propTypes } from "react-bootstrap/esm/Image";
import { Button, Col, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { useCallback, useEffect, useReducer } from "react";
import { getAllChanges, getAllData, saveChange, saveData } from "../../idb.js"
import { post } from "helpers/api_helper.js";
import moment from "moment";


const intialFormDetails = {
    public: false,
    billable: true,
    files: [],
    id: {
        value: "",
        error: "",
    },
    subject: {
        value: "",
        error: "",
    },
    status: {
        value: "",
        error: "",
    },
    hourlyRate: {
        value: "",
        error: "",
    },
    startDate: {
        value: "",
        error: "",
    },
    endDate: {
        value: "",
        error: "",
    },
    priority: {
        value: 2,
        error: "",
    },
    repeateEvery: {
        value: "",
        error: "",
    },
    relatedTo: {
        value: "",
        error: "",
    },
    assignedBy: {
        value: "",
        error: "",
    },

    tags: {
        value: [],
        error: "",
    },

    description: {
        value: "",
        error: "",
    },
    onSubmit: {
        value: "",
        error: "",
    },
}

export async function handlePostChanges(data) {
    if (data.id && data.id.toString().length != 13) {
        await post(`${process.env.REACT_APP_DOMAIN}/task/${data.id}`, data);
    } else {
        await post(`${process.env.REACT_APP_DOMAIN}/create_task`, data);
    }
}

async function handlePost(data) {
    if (data.id && data.id.toString().length != 13) {
        const result = await post(`${process.env.REACT_APP_DOMAIN}/task/${data.id}`, data);

        if (result && result?.success) {
            alert("Task updated successfully.");
        }
    } else {
        const result = await post(`${process.env.REACT_APP_DOMAIN}/create_task`, data);

        if (result && result?.success) {
            alert("Task created successfully.");
        }
    }
}

function taskReducer(state, action) {
    const { type, payload, name } = action;
    switch (type) {
        case 'tags':
            return {
                ...state, tags: {
                    value: payload,
                    error: "",
                }
            }
        case "public":
            return {
                ...state, [name]: payload,
            }
        case "billable":
            return {
                ...state, [name]: payload,
            }
        case "singleTask":
            return payload;
        case "reset":
            return intialFormDetails;
        default:
            return {
                ...state, [name]: {
                    value: payload,
                    error: "",
                }
            };
    }
}

const CreateTask = ({ modalStandard, setModalStandard, singleTaskData, fetchData }) => {

    const [form, dispatch] = useReducer(taskReducer, intialFormDetails)

    const tog_standard = () => {
        setModalStandard(!modalStandard);
        if (modalStandard) {
            dispatch({ type: "reset" })
        }
        removeBodyCss();
    };

    const handleSetSingleTaskData = useCallback(() => {
        if (singleTaskData?.id) {
            const {
                id,
                name,
                description,
                priority,
                startdate,
                duedate,
                repeat_every,
                recurring_type,
                rel_type,
                is_public,
                billable,
                hourly_rate,
                status,
            } = singleTaskData;

            const taskDetails = {
                public: !!is_public,
                billable: !!billable,
                files: [],
                id: {
                    value: id,
                    error: "",
                },
                status: {
                    value: status,
                    error: "",
                },
                subject: {
                    value: name || "",
                    error: "",
                },
                hourlyRate: {
                    value: hourly_rate || "",
                    error: "",
                },
                startDate: {
                    value: moment(startdate).format("YYYY-MM-DD") || "",
                    error: "",
                },
                endDate: {
                    value: moment(duedate).format("YYYY-MM-DD") || "",
                    error: "",
                },
                priority: {
                    value: priority || 2,
                    error: "",
                },
                repeateEvery: {
                    value: `${repeat_every} ${recurring_type != "" && recurring_type != "Months" && recurring_type != "Weeks" ? recurring_type[0].toUpperCase() + recurring_type.slice(1,) : recurring_type}` || "",
                    error: "",
                },
                relatedTo: {
                    value: rel_type || "",
                    error: "",
                },
                assignedBy: {
                    value: "Default Admin",
                    error: "",
                },
                tags: {
                    value: [],
                    error: "",
                },
                description: {
                    value: description?.replaceAll("<p>", "").replaceAll("</p>", "") || "",
                    error: "",
                },
                onSubmit: {
                    value: "",
                    error: "",
                },
            }

            dispatch({ type: "singleTask", payload: taskDetails });
        }
    }, [singleTaskData]);

    useEffect(() => {
        handleSetSingleTaskData();
    }, [singleTaskData])

    const removeBodyCss = () => {
        document.body.classList.add("no_padding");
    };

    const handleForm = (e) => {
        const { type, name, value, checked } = e.target;

        dispatch({ type: name, payload: type === "checkbox" ? checked : value, name: name })
    }

    async function handleFormSubmit() {
        const variables = {
            id: form.id.value,
            name: form.subject.value,
            description: form.description.value,
            is_public: form.public ? 1 : 0,
            billable: form.billable ? 1 : 0,
            startdate: form.startDate.value ? moment(form.startDate.value).format("YYYY-MM-DD") : "",
            duedate: form.endDate.value ? moment(form.endDate.value).format("YYYY-MM-DD") : "",
            status: form.status.value,
            repeat_every: form.repeateEvery.value?.split(" ")[0],
            recurring_type: form.repeateEvery.value?.split(" ")[1],
            hourly_rate: form.hourlyRate.value,
            priority: parseInt(form.priority.value) || 3,
            rel_type: form.relatedTo.value,
        };

        if (navigator.onLine) {
            try {
                await handlePost(variables);
                tog_standard();
                fetchData();
            } catch (e) {
                console.log(e);
            }
        } else {
            if (variables.id) {
                const offLineData = await getAllData("tasks");
                const offLineChanges = await getAllChanges("changes");
                const offLineTaskUpdates = offLineData.find(each => each.id == variables.id);

                await saveData("tasks", { ...offLineTaskUpdates, ...variables, id: parseInt(variables.id) });
                const findChangeIsExistOrNot = offLineChanges.find(each => each.id == variables.id);

                if (findChangeIsExistOrNot) {
                    await saveChange("changes", { ...findChangeIsExistOrNot, ...variables });
                } else {
                    await saveChange("changes", variables);
                }

                alert("Task updated successfully.");
                tog_standard();
                fetchData();
            } else {
                const offlineVariables = { ...variables, id: parseInt(Date.now().toString()) }
                await saveData("tasks", offlineVariables);
                await saveChange("changes", offlineVariables);
                alert("Task created successfully.");
                tog_standard();
                fetchData();
            }
        }
    }

    return (
        <div>
            <button className="btn btn-primary" onClick={tog_standard}>
                Create Task
            </button>
            <Modal isOpen={modalStandard} toggle={tog_standard} size="lg">
                <ModalHeader toggle={tog_standard}>Add New Task</ModalHeader>
                <ModalBody className="py-6">
                    <Row >
                        <Col xs="12" md="10">
                            {/* {<p>{JSON.stringify(form)}</p>} */}
                            <div className="btn-group" role="group" aria-label="Basic checkbox toggle button group">
                                <FormGroup check>
                                    <Input
                                        type="checkbox"
                                        name="public"
                                        checked={form.public}
                                        onChange={handleForm}
                                        id="public_tc"
                                    />
                                    <Label for="public_tc" check>
                                        Public
                                    </Label>
                                </FormGroup>
                                <FormGroup check className="mx-4">
                                    <Input
                                        type="checkbox"
                                        name="billable"
                                        checked={form.billable}
                                        onChange={handleForm}
                                        id="billable_tc"
                                    />
                                    <Label for="billable_tc" check>
                                        Billable
                                    </Label>
                                </FormGroup>
                            </div>
                        </Col>
                        <Col xs="12" md="2">
                            {/* <p color="primary">
                            Attach files
                        </p> */}
                        </Col>
                    </Row>
                    <Row>
                        <Col className="col-12">
                            <Form>
                                {/* <FormUpload /> */}
                                <FormGroup>
                                    <Label for="subject_ct">
                                        Subject
                                    </Label>
                                    <Input
                                        id="subject_ct"
                                        name="subject"
                                        placeholder=""
                                        type="text"
                                        onChange={handleForm}
                                        value={form.subject.value}
                                    />
                                </FormGroup>

                                <FormGroup>
                                    <Label for="hourly_rate_ct">
                                        Hourly Rate
                                    </Label>
                                    <Input
                                        id="hourly_rate_ct"
                                        name="hourlyRate"
                                        placeholder=""
                                        type="text"
                                        onChange={handleForm}
                                        value={form.hourlyRate.value}
                                    />
                                </FormGroup>
                                <Row>
                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="start_date_ra">
                                                Start Date
                                            </Label>
                                            <Input
                                                id="start_date_ra"
                                                name="startDate"
                                                placeholder=""
                                                type="date"
                                                onChange={handleForm}
                                                value={form.startDate.value}
                                            />
                                        </FormGroup>
                                    </Col>

                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="end_date_ct">
                                                End Date
                                            </Label>
                                            <Input
                                                id="end_date_ct"
                                                name="endDate"
                                                placeholder=""
                                                type="date"
                                                onChange={handleForm}
                                                value={form.endDate.value}
                                            />
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="priority_ct">
                                                Priority
                                            </Label>
                                            <Input
                                                id="priority_ct"
                                                name="priority"
                                                type="select"
                                                onChange={handleForm}
                                                value={form.priority.value}
                                            >
                                                <option value={2}>Medium</option>
                                                <option value={1}>Low</option>
                                                <option value={3}>High</option>
                                                <option value={4}>Urgent</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="repeate_every_ct">
                                                Repeat Every
                                            </Label>
                                            <Input
                                                id="repeate_every_ct"
                                                name="repeateEvery"
                                                type="select"
                                                onChange={handleForm}
                                                value={form.repeateEvery.value}
                                            >
                                                <option value=""></option>
                                                <option value="Week">Week</option>
                                                <option value="2 Weeks">2 Weeks</option>
                                                <option value="1 Month">1 Month</option>
                                                <option value="3 Months">3 Months</option>
                                                <option value="6 Months">6 Months</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="related_to_ct">
                                                Related To
                                            </Label>
                                            <Input
                                                id="related_to_ct"
                                                name="relatedTo"
                                                type="select"
                                                value={form.relatedTo.value}
                                                onChange={handleForm}
                                            >
                                                <option value=""></option>
                                                <option value={"Project"}>Project</option>
                                                <option value={"Invoices"}>Invoices</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>

                                    <Col className="col-12 col-md-6">
                                        <FormGroup>
                                            <Label for="assigned_by_ct">
                                                Assigned By
                                            </Label>
                                            <Input
                                                id="assigned_by_ct"
                                                name="assignedBy"
                                                type="select"
                                                value={form.assignedBy.value}
                                            >
                                                <option className="bg-white" value="">Default Admin</option>
                                            </Input>
                                        </FormGroup>
                                    </Col>
                                </Row>

                                {/* <FormGroup>
                                    <Suspense fallback={<div>Loading...</div>}>
                                        <ChipInput form={form} dispatch={dispatch} />
                                    </Suspense>
                                </FormGroup> */}

                                <FormGroup>
                                    <Label for="description_ct">
                                        Description
                                    </Label>
                                    <Input
                                        id="description_ct"
                                        name="description"
                                        placeholder=""
                                        type="textarea"
                                        rows={3}
                                        onChange={handleForm}
                                        // dangerouslySetInnerHTML={{ __html: form.description.value }}
                                        value={form.description.value}
                                    />
                                </FormGroup>
                            </Form>
                        </Col>
                    </Row>

                </ModalBody>
                <ModalFooter className="d-block d-md-flex">
                    <Row>
                        <Col className="col-12 col-md-6">
                            <Button className="btn btn-white w-100 w-md-lg my-1" onClick={tog_standard}>
                                Close
                            </Button>
                        </Col>
                        <Col className="col-12 col-md-6">
                            <button className="btn btn-primary w-100 w-md-lg my-1" onClick={() => handleFormSubmit()}>
                                Save
                            </button>
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </div>
    )
}

export default CreateTask;