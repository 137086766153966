import PropTypes from "prop-types";
import React, { useState } from "react";

//i18n
import { withTranslation } from "react-i18next";
import { Container } from "reactstrap";

const Dashboard = (props) => {
  const [menu, setMenu] = useState(false);
  const toggle = () => {
    setMenu(!menu);
  };
  document.title = "Dashboard | elbert - React Admin & Dashboard Template";
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div className="page-title-box">
            
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

Dashboard.propTypes = {
  t: PropTypes.any,
};

export default Dashboard
