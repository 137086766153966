import React, { lazy, Suspense, useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Row,
  Table,
} from "reactstrap";
import Male from '../../assets/images/tasks/Male.jpg';

import "./Tasks.scss";
import moment from "moment/moment";
import { get } from "helpers/api_helper";
import { clearChanges, getAllChanges, getAllData, saveChange, saveData } from "../../idb.js";
import { handlePostChanges } from "./CreateTask";

const CreateTask = lazy(() => import("./CreateTask"));

async function handleSyncOffileChanges() {
  try {
    const changes = await getAllChanges("changes");

    if (changes?.length) {
      changes.forEach(eachTask => handlePostChanges({
        ...eachTask,
        startdate: eachTask.startdate ? moment(eachTask.startdate).format("YYYY-MM-DD") : "",
        duedate: eachTask.duedate ? moment(eachTask.duedate).format("YYYY-MM-DD") : "",
      }));

      alert("Sync changes done");
    }
  } catch (e) {
    alert(e?.toString());
  }
}

function Tasks() {
  const [modalStandard, setModalStandard] = useState(false);
  const [tasksData, setTasksData] = useState({ data: [], singleTaskData: { id: 0 } });

  const handleReset = () => {
    setModalStandard(false);
    setTasksData(prev => {
      return { ...prev, singleTaskData: { id: 0 } };
    });
  }

  const handleSetModalStandard = async (id) => {

    if (navigator.onLine) {
      try {
        const data = await get(`/tasks/${id}`);
        if (data) {
          setTasksData(prev => {
            return { ...tasksData, singleTaskData: { ...prev.singleTaskData, ...data } }
          });
        }
      } catch (e) {
        handleReset();
      }
    } else {
      const offLineData = await getAllData("tasks");
      const data = offLineData.find(eachTask => eachTask.id == id);
      if (data) {
        setTasksData(prev => {
          return { ...tasksData, singleTaskData: { ...prev.singleTaskData, ...data } }
        });
      }
    }
    setModalStandard(!modalStandard);
  };

  const fetchData = async () => {
    if (navigator.onLine) {
      try {
        const data = await get("/tasks");

        if (data) {
          data.forEach((eachTask) => saveData("tasks", eachTask));
          setTasksData({ ...tasksData, data: data });
        }
      } catch (e) {
        console.log(e);
      }
    } else {
      const offLineData = await getAllData("tasks");
      offLineData.sort((a, b) => b.id - a.id);
      setTasksData({ ...tasksData, data: offLineData });
    }
  }
  useEffect(() => {
    fetchData();
  }, []);

  const handleSyncData = async () => {
    try {
      await handleSyncOffileChanges();
      fetchData();
      await clearChanges("changes");
    } catch (e) {
      console.log(e);
    }
  }

  useEffect(() => {
    window.addEventListener("online", handleSyncData)
    return () => {
      window.removeEventListener("online", handleSyncData)
    }
  }, []);

  const priority = [
    "", "Low", "Medium", "High", "Urgent"
  ]

  async function handleOffLineUpdates(name, value, task) {
    const offLineData = await getAllData("tasks");
    const offLineChanges = await getAllChanges("changes");
    // const offLineTaskUpdates = offLineData.map(each => {
    //   if (each.id == task.id) {
    //     return { ...each, id: parseInt(each.id), [name]: parseInt(value) || 0 }
    //   }

    //   return each;
    // });

    // await clearChanges("tasks");

    // offLineTaskUpdates.forEach(each => saveData("tasks", each));
    const offLineTaskUpdates = offLineData.find(each => each.id == task.id);

    await saveData("tasks", { ...offLineTaskUpdates, ...task, id: parseInt(task.id), [name]: parseInt(value) || 0 });

    const findChangeIsExistOrNot = offLineChanges.find(each => each.id == task.id);

    if (findChangeIsExistOrNot) {
      await saveChange("changes", { ...findChangeIsExistOrNot, ...task, [name]: parseInt(value) || 0 });
    } else {
      await saveChange("changes", { ...task, [name]: parseInt(value) || 0 });
    }

    fetchData();
  }

  const handleTaskUpdates = (e, task) => {
    const { name, value } = e.target;
    const variables = {
      ...task,
      startdate: task.startdate ? moment(task.startdate).format("YYYY-MM-DD") : "",
      duedate: task.duedate ? moment(task.duedate).format("YYYY-MM-DD") : "",
      [name]: parseInt(value) ?? 0
    }

    if (navigator.onLine) {
      handlePostChanges(variables);
      fetchData();
    } else {
      handleOffLineUpdates(name, value, task);
    }

  }

  return (
    <>
      <div className="page-content">
        <Container fluid={true} className="py-4">
          <Row>
            <Row className="d-flex justify-content-between align-items-center">
              <Col xs="12" md="6">
                <h3>Tasks Summary</h3>
                <p>Here's your overview of tasks</p>
              </Col>
              <Col xs="12" md="4" className="d-flex justify-content-end align-items-center w-auto">
                <Suspense fallback={<div>Loading...</div>}>
                  <CreateTask modalStandard={modalStandard} singleTaskData={tasksData.singleTaskData} setModalStandard={setModalStandard} fetchData={fetchData} />
                </Suspense>
                <button className="btn btn-success">
                  Tasks Overview
                </button>
              </Col>
            </Row>
            <Row className="py-3">
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <div className="table-responsive">
                      <Table striped>
                        <thead>
                          <tr>
                            <th><input type="checkbox"></input></th>
                            <th scope="row">ID</th>
                            <th scope="row">Task Name</th>
                            <th scope="row">Status</th>
                            <th scope="row">Start Date</th>
                            <th scope="row">Due Date</th>
                            <th scope="row">Assigned To</th>
                            {/* <th scope="row">Tags</th> */}
                            <th scope="row">Prioriry</th>
                            <th scope="row">Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {tasksData?.data?.map((task, index) => (
                            <tr key={index} >
                              <td><input type="checkbox" /></td>
                              <td>{task.id}</td>
                              <td>
                                <div className="">
                                  <p>{task.name}</p>
                                  <p className="fs-7">Project Name</p>
                                </div>
                              </td>
                              <td>
                                <Input
                                  id="status_ct"
                                  name="status"
                                  type="select"
                                  className="form-control"
                                  onChange={(e) => handleTaskUpdates(e, task)}
                                  value={task.status}
                                >
                                  <option value={1}>Not Started</option>
                                  <option value={4}>In Progress</option>
                                  <option value={3}>Testing</option>
                                  <option value={2}>Awaiting Feedback</option>
                                  <option value={5}>Completed</option>
                                </Input>
                              </td>
                              <td>
                                {moment(task.startdate).format('DD-MM-YYYY') || moment().format('DD-MM-YYYY')}
                              </td>
                              <td>
                                {
                                  moment(task.duedate).format('DD-MM-YYYY') || moment().add(6, "days").format('DD-MM-YYYY')
                                }
                              </td>
                              <td><img src={Male} width={30} height={30} alt="role" title="Default Admin" className="rounded-circle" /></td>
                              {/* <td>
                                <div>
                                  <Button
                                    // color={getColor(task.status)}
                                    className="btn btn-sm btn-white w-sm"
                                  >
                                    Broadband retailers
                                  </Button>
                                </div>
                              </td> */}
                              <td>
                                {/* {task.priority ? priority[task.priority] : ""} */}
                                <Input
                                  id="priority_ct"
                                  name="priority"
                                  type="select"
                                  className="form-control"
                                  value={task.priority}
                                  onChange={(e) => handleTaskUpdates(e, task)}
                                >
                                  <option value={1}>Low</option>
                                  <option value={2}>Medium</option>
                                  <option value={3}>High</option>
                                  <option value={4}>Urgent</option>
                                </Input>
                              </td>
                              <td>
                                <div className="d-flex gap-3 align-items-center">
                                  <i className="ti-pencil action-icon" title="Edit" onClick={() => handleSetModalStandard(task.id)}></i>
                                  <i className="ti-trash action-icon" title="Delete"></i>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Tasks;
