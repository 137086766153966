import React, { useState } from "react";
import { Form, Col, InputGroup } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import 'bootstrap/dist/css/bootstrap.min.css';


const AllRequisitions = () => {
  const [defaultSearchFilter, setDefaultSearchFilter] = useState("");

  const toggleVerticalNav = () => {
    console.log("Toggle Vertical Nav");
  };

  const simpleSearchJobList = (searchTerm) => {
    console.log("Search Term:", searchTerm);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      simpleSearchJobList(defaultSearchFilter);
    }
  };

  return (
    <div className="page-content">
      <div className="d-flex align-items-center">
        <i
          className="oorwin-collapse expandnav mb-1 mr-2"
          title="Collapse" // Using title attribute for tooltip
          onClick={toggleVerticalNav}
          role="button"
          tabIndex="0"
        ></i>
        <div className="search-wrapper active mb-1">
          <div className="input-group search">
            <input
              type="text"
              className="form-control"
              value={defaultSearchFilter}
              onChange={(e) => setDefaultSearchFilter(e.target.value)}
              onKeyPress={handleKeyPress}
              placeholder="Search Title, ID"
              aria-invalid="false"
            />
            <div className="input-group-append">
              <button
                className="btn btn-outline-search"
                type="button"
                onClick={() => simpleSearchJobList(defaultSearchFilter)}
              >
                <FontAwesomeIcon icon={faSearch} className="font-size-16 text-muted" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllRequisitions;
