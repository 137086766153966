import { openDB } from 'idb';

const keys = [
  {
    key: "tasks",
    keyPath: "id",
    autoIncrement: true,
  },
  {
    key: "changes",
    keyPath: "id",
    autoIncrement: true,
  },
];

const dbPromise = openDB('offline-db', 1, {
  upgrade(db) {
    keys.forEach((dbDetails) => {
      if (!db.objectStoreNames.contains(dbDetails.key)) {
        db.createObjectStore(dbDetails.key, { keyPath: dbDetails.keyPath, autoIncrement: dbDetails.autoIncrement });
      }
    });
  },
});

export async function getAllData(storeName) {
  const db = await dbPromise;
  return db.getAll(storeName);
}

export async function saveData(storeName, data) {
  const db = await dbPromise;
  return db.put(storeName, data);
}

export async function clearData(storeName) {
  const db = await dbPromise;
  return db.clear(storeName);
}

export async function getAllChanges(storeName) {
  const db = await dbPromise;
  return db.getAll(storeName);
}

export async function saveChange(storeName, change) {
  const db = await dbPromise;
  return db.put(storeName, change);
}

export async function clearChanges(storeName) {
  const db = await dbPromise;
  return db.clear(storeName);
}
